import React from "react"
import { graphql } from "gatsby"
import { Banner } from "./HeroBanner-types"
import "./hero.module.scss"
import { getBackgroundImageUrlByBreakpoint } from "../../assets/helper/getBackgroundImageUrlByBreakpoint"
import { HeroContent } from "./HeroContent"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { HeroHighlights } from "components/highlights/Hero/HeroHighlights"

interface HeroProps {
  banner: Banner
}

export const HeroBanner = ({ banner }: HeroProps) => {
  const { size } = useWindowDimensions()

  const {
    eyebrow_text,
    heading,
    sub_heading,
    buttons,
    secondary_image,
    components,
  } = banner

  const highlights = components?.modular_content?.filter(
    component => component?.system?.type === "highlights_group"
  )[0]?.elements?.highlights?.modular_content

  const backgroundImageCSSVariable = secondary_image?.value[0]?.url
    ? getBackgroundImageUrlByBreakpoint({
      variableName: "hero-background-image",
      imageUrl: secondary_image.value[0].url,
      breakpoints: {
        mobile: {
          width: 768,
        },
      },
    })
    : {}

  return (
    <section className={`fr-hero`} style={backgroundImageCSSVariable}>
      <div className={`fr-container fr-container--large`}>
        <div className={`hero-banner-content__wrapper`}>
          <HeroContent
            eyebrowText={eyebrow_text?.value}
            heading={heading?.value}
            subHeading={sub_heading}
            buttons={buttons?.value}
            baseClassName={`hero-banner-content`}
          >
            {size >= 768 && highlights && highlights.length > 0 && (
              <div className={`hero-banner-content__highlights`}>
                <HeroHighlights highlights={highlights} />
              </div>
            )}
          </HeroContent>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HeroBannerFragment on kontent_item_hero_banner {
    id
    elements {
      eyebrow_text {
        value
      }
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      tagline {
        value
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      components {
        modular_content {
          ...FormFragment
          ...HighlightGroupFragment
          ...CouponFragment
        }
      }
      buttons {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
