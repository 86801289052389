import React from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import { SEO } from "components/seo/seo"
import { HeroBanner } from "components/hero/HeroBanner"
import { GlobalFooter } from "components/global-footer/GlobalFooter"
import { useLogos } from "hooks/queries/settings/useLogos"
import { ContentBlocks } from "components/content-blocks/ContentBlocks"
import { Layout } from "components/layout"
import { StubBanner } from "components/form-stub/StubBanner"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { TabbedHeroBanner } from "components/hero/TabbedHeroBanner"

type PageContext = PageProps["pageContext"] & {
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  linkedPagesUrlPathCodex: Record<string, string>
}

interface IndexPageProps extends PageProps {
  pageContext: PageContext
}

const IndexPage: React.FC<IndexPageProps> = ({ location, pageContext }) => {
  const { pathname, hash } = location
  const { linkedPagesUrlPathCodex } = pageContext

  const { globalFormStubBanner } = useGeneralSettings()

  const { headerLogo, faviconLogo } = useLogos()

  const { allKontentItemPage } = useStaticQuery(getHomePage)

  const [home] = allKontentItemPage.nodes

  const {
    seo_metadata__meta_title,
    seo_metadata__meta_description,
    seo_metadata__canonical_link,
    seo_metadata__rich_data_image,
    alternating_content,
    banner,
    rich_content,
    global_footer,
  } = home.elements
  const blockAfterFormStubHasHeading = rich_content?.modular_content[0]
    ?.elements?.heading?.value
    ? true
    : false
  const hasAlternatingContentBlocks =
    alternating_content.value[0]?.codename === "no" ? false : true
  return (
    <Layout
      location="/"
      pathName={pathname}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
      isHomepage
    >
      <SEO
        title={seo_metadata__meta_title?.value}
        description={seo_metadata__meta_description?.value}
        canonical={seo_metadata__canonical_link?.value}
        image={seo_metadata__rich_data_image}
        fallbackImage={headerLogo}
        favicon={faviconLogo}
      />
      {banner.value[0].elements.hasOwnProperty("hero_tabs") ? (
        <TabbedHeroBanner banner={banner.value[0].elements} />
      ) : (
        <HeroBanner banner={banner.value[0].elements} />
      )}

      {globalFormStubBanner && Object.keys(globalFormStubBanner).length > 0 && (
        <StubBanner
          formStub={globalFormStubBanner}
          nextBlockHasHeading={blockAfterFormStubHasHeading}
        />
      )}

      {rich_content?.modular_content?.length > 0 && (
        <ContentBlocks
          content={rich_content.modular_content}
          hasAlternatingContentBlocks={hasAlternatingContentBlocks}
        />
      )}

      {global_footer?.value.length > 0 && (
        <GlobalFooter content={global_footer} />
      )}
    </Layout>
  )
}
const getHomePage = graphql`
  query Home {
    allKontentItemPage(filter: { elements: { slug: { value: { eq: "/" } } } }) {
      nodes {
        elements {
          alternating_content {
            value {
              codename
            }
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          seo_metadata__rich_data_image {
            value {
              url
            }
          }
          banner {
            value {
              ...HeroBannerFragment
              ...TabbedHeroBannerFragment
            }
          }
          rich_content {
            ...ContentBlocksParserFragment
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
        }
      }
    }
  }
`

export default IndexPage
